.footerLayout{
    width: 100%;
}
.custom-link {
    text-decoration: none;
    color: var(--gray-gray_tsp_02, #949494);
    /* help-font,helvetica neue,pingfang SC,arial,hiragino sans gb,microsoft yahei ui,microsoft yahei,simsun,sans-serif; */
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-size: 14.556px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.873px; /* 157.143% */
}
.footerContentLayout{
    width: 100%;
    background-color: #333333;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 49.9px;
    
}

.footerLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* min-width: 700px; */
}
.footerLeft .footerLeftIcon {
    width: 36.364px;
    height: 45px;
}

.footerLeftText {
    margin-left: 24px;
    color: var(--gray-gray_tsp_02, #949494);
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-size: 14.556px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.873px; /* 157.143% */
}

.footerRight {
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    
    color: var(--gray-gray_tsp_02, #949494);
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-size: 14.556px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.873px; /* 157.143% */
}

.footerRight .complaint-mail {
    margin-top: 8px;
}

.wanganImg {
    width: 28px;
    height: 28px;
    margin-right: 6px;

}
.wangan-container {
    margin: 8px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.firstlineContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobile-footer-layout {
    width: 100%;
}

.mobile-footer-content-layout {
    width: 100%;
    background-color: #333333;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 32px;
}

.footer-icon {
    width: 36.364px;
    height: 45px;
}

.mobile-footer-second-part {
    margin-top: 24px;
}

.mobile-footer-third-part {
    margin-top: 24px;
}

.mobile-footer-second-part {
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    color: var(--gray-gray_tsp_02, #949494);
    /* text_02 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.mobile-footer-third-part {
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    color: var(--gray-gray_tsp_02, #949494);
    /* text_02 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
}