.statement-layout-with-footer {
    width: 100%;
    height: 100%;
}
.statementLayout {
    display: flex;
    width: 100%;
    height: 100vh;
    /* padding-top: 24px; */
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    
    /* min-height: 800px; */
}
.nowrap-text{
    flex-wrap: nowrap;
}
.statementContentLayout{
    margin-top: 82px;
    /* margin-bottom: 82px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 100%;
    /* min-width: 800px; */
    /* min-height: 400px; */
    background: #FCFCFC;
    box-shadow: 0px 4px 200px rgba(138, 99, 249, 0.1);
    border-radius: 30px;
    padding: 90px 180px 90px 180px;
    overflow: auto;
}
@media screen and (max-width: 1000px) {
    .statementContentLayout{
        padding: 90px 90px 90px 90px;
    }
}
.statementTitle {

    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    flex-wrap: nowrap;
    color: #6347D9;
}

.statementTextContent {

    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;

    color: #000000;
    margin-top: 32px;
}

.slLogo {
    height: 20px;
}