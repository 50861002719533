
.about-layout-footer {
    width: 100%;
    height: 100%;
}
.aboutLayout {
    display: flex;
    width: 100%;
    height: 100vh;
    /* padding-top: 24px; */
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    /* min-height: 800px; */

    /* background-image: url('@/images/aboutBg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom -200px left 0;  */
}
.aboutContentLayout{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* min-width: 800px; */
    /* min-height: 400px; */
    
    /* background: #FCFCFC; */
    /* box-shadow: 0px 4px 200px rgba(138, 99, 249, 0.1); */
    border-radius: 30px;
    padding: 90px 180px 90px 180px;
    /* background-color: red; */
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}
@media screen and (max-width: 1000px) {
    .aboutContentLayout{
        padding: 90px 90px 90px 90px;
    }
    
}

.about-content-bg-container {
    position: absolute;
    left: 0;
    top: 231px;
    z-index: -1;
    width: 100%;
    height: 100%;
}
.about-content-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.aboutContent {
    /* width: 771px;
    height: 264px; */
    /* overflow: auto; */
    /* margin-top: 177px;
    margin-left: 300px; */
    /* height: 45%; */
    /* background-color: aqua; */
    overflow: auto;
    margin-bottom: 15%;
}
.logoTitle {
    width: 129.76px;
    /* width: 200px; */
    height: 44px;
}
.aboutTitle {

    /* font-family: 'Alibaba PuHuiTi 2.0';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;

    color: #6347D9; */
}

.aboutTextContent {
    margin-top: 48px;
    color: #444;
    white-space: pre-line;
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 30.6px */
}
