.waitLayout {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding-top: 20px;
    box-sizing: border-box;
}
.waitContentLayout{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 90%;
    min-width: 900px;
    min-height: 400px;
    background: #FCFCFC;
    box-shadow: 0px 4px 200px rgba(138, 99, 249, 0.1);
    border-radius: 30px;
    padding: 90px 180px 90px 180px;
}
/* @media screen and (max-width: 1000px) {
    .waitContent{
        padding: 90px 90px 90px 90px;
    }
    
} */
.waitContentLayout p {
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 100px;

    color: #6347D9;
}