
.privacyLayout {
    display: flex;
    width: 100%;
    height: 100%;
    /* padding-top: 20px; */
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
}
.privacyContent {
    box-sizing: border-box;
    width: 80%;
    height: 90%;
    background-color: blue;
    padding: 50px 180px 50px 180px;
    display: flex;
    flex-direction: column;
    min-width: 900px;
    min-height: 400px;
    background: #FCFCFC;
    box-shadow: 0px 4px 200px rgba(138, 99, 249, 0.1);
    border-radius: 30px;
}
@media screen and (max-width: 1000px) {
    .privacyContent{
        padding: 50px 90px 50px 90px;
    }
    
}
.privacyTitle {
    width: fit-content;
    height: 50px;

    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;


    color: #6347D9;

}

.privacyTime {
    margin-top: 32px;
}
.privacyTimeText {
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.privacyItems{
    overflow-y: auto;
    margin-top: 32px;
}
.privacyItem {
    margin-top: 32px;
}
.privacyItem:nth-child(1){
    margin-top: 0px;
}
.privacyItemTitle {
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

.privacyItemContent {
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;

    color: #000000;
}

.bubble1 {
    width: 150px;
    height: 150px;
    border-radius: 75px;
    background: linear-gradient(86.75deg, #BAB9FF -0.68%, #E8DDFF 80.31%);
    transform: rotate(-165deg);
    position: absolute;
    /* left: 0;
    top: 30%; */
}
.bubble2 {
    width: 110px;
    height: 110px;
    border-radius: 55px;
    background: linear-gradient(86.75deg, #BAB9FF -0.68%, #E8DDFF 80.31%);
    position: absolute;
    /* left: 70%;
    top: 50px; */
}
.bubble3 {
    width: 250px;
    height: 250px;
    border-radius: 125px;
    background: linear-gradient(86.75deg, #BAB9FF -0.68%, #E8DDFF 80.31%);
    position: absolute;
    /* right: 0;
    top: 60%; */
}