.home-layout-with-footer {
    width: 100%;
    height: 100%;
    
}
.homeLayout {
    width: 100%;
    height: 100vh;
    /* padding-top: 24px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* background: url('@/images/bgL2.svg') no-repeat left 0 bottom -65px, 
                url('@/images/bgL1.svg') no-repeat left 0 bottom 97.5px, 
                url('@/images/bgR1.svg') no-repeat right 0 bottom -31px, 
                url('@/images/bgR2.svg') no-repeat right 0 bottom 115px; */
    
    min-height: 934px;
}

.homeLink {
    text-decoration: none; 
    display: block;
    box-sizing: border-box;
    /* width: calc(170*100*var(--rootvw)/var(--width)); */
    /* width: auto;
    height: calc(50*100*var(--rootvh)/var(--height));
    min-width: calc(170px*var(--scale));
    min-height: calc(50px*var(--scale)); */
    border: 1px solid #E6E6E6;
    /* border-radius: calc(50*100*var(--rootvh)/var(--height)/2); */
    border-radius: 34.5px;
    cursor: pointer;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-right: calc(40*100*var(--rootvw)/var(--width));
    padding-left: calc(40*100*var(--rootvw)/var(--width)); */
    transition: all 0.3s;
    padding: 12px 24px;
}

.homeLink.android {
    /* margin-left: calc(24*100*var(--rootvw)/var(--width));
    width: calc(210*100*var(--rootvw)/var(--width));
    min-width: calc(220px*var(--scale)); */
    margin-left: 24px;
}
.homeContent {
    /* width: calc(742.41*100*var(--rootvw)/var(--width));
    height: calc(602*100*var(--rootvh)/var(--height));
    min-width: calc(741.41px*var(--scale));
    min-height: calc(602px*var(--scale)); */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 107px;
    padding-bottom: 187.4px;
    box-sizing: border-box;
    position: relative;
    /* margin-top: calc(97*100*var(--rootvh)/var(--height)); */
    /* background-image: url('@/images/bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom 0 left 0; */
    

}
.home-content-bg-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}
.home-content-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}
.homeContent > div {
    display: flex;
    flex-direction: row;
}
.homeLeft {
    /* height: calc(602*100*var(--rootvh)/var(--height));
    width: calc(297.41*100*var(--rootvw)/var(--width));
    min-width: calc(297.41px*var(--scale));
    min-height: calc(602px*var(--scale)); */
    /* width: 297.40598px;
    height: 602px; */
    height: calc(602*100*var(--rootvh)/var(--height));
    width: calc(297.41*100*var(--rootvw)/var(--width));
    min-width: 297.41px;
    min-height: 602px;
}
.homeLeft .preview {
    height: calc(602*100*var(--rootvh)/var(--height));
    width: calc(297.41*100*var(--rootvw)/var(--width));
    /* min-width: calc(297.41px*var(--scale));
    min-height: calc(602px*var(--scale)); */
    /* width: 297.40598px;
    height: 602px; */
    min-width: 297.41px;
    min-height: 602px;
}



.homeRight {
    /* margin-left: calc(56*100*var(--rootvw)/var(--width));
    width: calc(397*100*var(--rootvw)/var(--width));
    min-width: calc(397px*var(--scale)); */
    width: 345px;
    margin-left: 48px;
}
.homeTitleAndDesc {
    margin-top: 75px;
    padding-left: 8px;
    box-sizing: border-box;
}
.homeTitle {
    /* margin-left: calc(8*100*var(--rootvw)/var(--width)); */
    /* width: calc(256*100*var(--rootvw)/var(--width));
    height: calc(143*100*var(--rootvh)/var(--height)); */
    /* width: auto;
    height: auto;
    min-width: calc(256px*var(--scale));
    min-height: calc(143px*var(--scale)); */
    /* margin-top: calc(75*100*var(--rootvh)/var(--height)); */
}
.homeTitle img {
    /* width: calc(256*100*var(--rootvw)/var(--width)); */
    /* height: calc(143*100*var(--rootvh)/var(--height)); */
    /* min-width: calc(256px*var(--scale));
    min-height: calc(143px*var(--scale)); */
    width: 256px;
    height: 143px;
}
.homeDesc {
    /* margin-left: calc(8*100*var(--rootvw)/var(--width)); */
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
    color: #333333;
    white-space: nowrap;
    /* margin-top: calc(24*100*var(--rootvh)/var(--height)); */
    margin-top: 24px;
}

.homeAndroidIcon {
    /* width: calc(26*100*var(--rootvw)/var(--width));
    height: calc(28*100*var(--rootvh)/var(--height));
    min-width: calc(26px*var(--scale));
    min-height: calc(28px*var(--scale)); */
    width: 21.517px;
    height: 24px;
}
.homeAppleIcon {
    /* width: calc(26*100*var(--rootvw)/var(--width));
    height: calc(30*100*var(--rootvh)/var(--height));
    min-width: calc(26px*var(--scale));
    min-height: calc(30px*var(--scale)); */
    width: 20.8px;
    height: 23.999px;
}
.buttons{
    /* margin-left: calc(4*100*var(--rootvw)/var(--width));
    margin-top: calc(90*100*var(--rootvh)/var(--height)); */
    margin-top: 120px;
    padding-left: 4px;
    display: flex;
    flex-direction: row;
}
/* .mobileButtons {
    margin-top: 120px;
    padding-left: 4px;
    display: flex;
    flex-direction: column;
} */
.homeButton {
    box-sizing: border-box;
    /* width: calc(170*100*var(--rootvw)/var(--width)); */
    /* width: auto;
    height: calc(50*100*var(--rootvh)/var(--height));
    min-width: calc(170px*var(--scale));
    min-height: calc(50px*var(--scale)); */
    border: 1px solid #E6E6E6;
    /* border-radius: calc(50*100*var(--rootvh)/var(--height)/2); */
    border-radius: 34.5px;
    cursor: pointer;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-right: calc(40*100*var(--rootvw)/var(--width));
    padding-left: calc(40*100*var(--rootvw)/var(--width)); */
    transition: all 0.3s;
    padding: 12px 24px;
}

.homeButton:hover {
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);
    transform: translateY(-2px);
    
}
.homeButton.android {
    /* margin-left: calc(24*100*var(--rootvw)/var(--width));
    width: calc(210*100*var(--rootvw)/var(--width));
    min-width: calc(220px*var(--scale)); */
    margin-left: 24px;
}

.homeButtonText {
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 400;
    /* font-size: calc(20*100*var(--rootvh)/var(--height)); */
    font-size: 20px;
    /* line-height: calc(28*100*var(--rootvh)/var(--height)); */
    color: #333333;
    /* margin-left: calc(16*100*var(--rootvw)/var(--width)); */
    line-height: normal;
    margin-left: 12px;
    display: inline-block;
    word-break: keep-all;
}


.home-button:hover {
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);
    transform: translateY(-2px);
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
}

.home-button {
    border: none;
    display: flex;
    padding: 16px 36px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 100px;
    background: var(--primarypurple-primarypurple---linear, linear-gradient(135deg, #997AFF 0%, #837AFF 100%));
    backdrop-filter: blur(10px);
    cursor: pointer;
}

.home-button-text {
    color: var(--white, #FFF);
    /* heading_02 */
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
}


.mobile-home-layout {
    width: 100%;
    height: 100vh;
    /* padding-top: 24px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    min-height: 934px;
    /* background: url('@/images/bgL2.svg') no-repeat left 0 bottom -65px, 
                url('@/images/bgL1.svg') no-repeat left 0 bottom 97.5px, 
                url('@/images/bgR1.svg') no-repeat right 0 bottom -31px, 
                url('@/images/bgR2.svg') no-repeat right 0 bottom 115px; */
    
    /* background-image: url('@/images/bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom 0 left 0;
    min-height: 800px; */
}

.mobile-home-content {
    /* width: calc(742.41*100*var(--rootvw)/var(--width));
    height: calc(602*100*var(--rootvh)/var(--height));
    min-width: calc(741.41px*var(--scale));
    min-height: calc(602px*var(--scale)); */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 33px;
    box-sizing: border-box;
    position: relative;
    /* justify-content: center; */
    /* margin-top: calc(97*100*var(--rootvh)/var(--height)); */

}