.contact-layout-with-footer {
    width: 100%;
    height: 100%;
}
.contactLayout {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* padding-top: 20px; */
    box-sizing: border-box;
    /* min-height: 800px; */
}
.contactContentLayout{
    overflow: auto;
    margin-top: 82px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 100%;
    /* min-width: 800px; */
    /* min-height: 400px; */
    background: #FCFCFC;
    box-shadow: 0px 4px 200px rgba(138, 99, 249, 0.1);
    border-radius: 30px;
    padding: 90px 180px 90px 180px;
}
@media screen and (max-width: 1000px) {
    .contactContentLayout{
        /* padding: 90px 90px 90px 90px; */
        padding: 20px;
    }
    
}

.contactContentLayout div {
    display: flex;
    flex-direction: column;
    /* min-width: 600px; */
}
.contactContentLayout .contactItem{
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 16px;
}


.contactTitle {
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;

    /* primary-text */

    color: #6347D9;
}
.contactItemTitle {
    /* display: inline-block;
    width: 100px; */
    
    /* h-p */
    
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    
    /* secondary-text */
    
    color: #999AA3;
}

.contactItemContent {
    /* display: inline-block;
    width: 150px; */

    /* h4 */
    
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    
    /* black */
    
    color: #333333;

    margin-left: 40px;
}

.part1 {
    margin-top: calc(48px - 16px);
}

.part2 {
    margin-top: calc(35px - 16px);
}

.part3 {
    margin-top: calc(35px - 16px);
}