
.navLayout {
    width: 100%;
    /* height: 60px; */
    /* justify-content: center; */
    box-sizing: border-box;
    padding: 12px 48px;
}
.navContent {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    /* width: 90%; */
}
.navLeft{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 0px;
    /* min-width: 230px; */
}
.navLeft .logo{
    width: 186.03px;
    height: 38px;
}


.navRight{
    /* min-width: 600px; */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-top: 16px;
    gap: 24px;
    box-sizing: border-box;
    /* justify-content: flex-end; */
}
.navRight .item{
    box-sizing: border-box;
    padding: 0px 10px;
    padding-bottom: 10px;
    margin-bottom: 6px;
    /* margin-left: 24px; */
    
}
.navRight .item .link-text-container {
    cursor: pointer;
    transition-duration: 400ms;
    transition-property: color;
    /* display: block; */
    position: relative;
}
/* .navRight .item .linkText:focus,
.navRight .item .linkText:hover {
  color: #fff;
} */

/* .navRight .item .link-text-container:focus:after,
.navRight .item .link-text-container:hover:after {
  width: 26px;
  left: 50%;
  transform: translate(-50%, 0);
}

.navRight .item .link-text-container:after {
    content: "";
    pointer-events: none;
    bottom: -6px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 4px;
    border-radius: 10px;
    background-color: var(--primary-text, #6347D9);
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
  } */

.navRight > .item:first-child{
    /* margin-left: 0px; */
}

.navRight .selected-item {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    padding: 0px 10px;
    padding-bottom: 10px;
    /* margin-left: 24px; */
    cursor: pointer;
}
.navRight > .selected-item:first-child{
    /* margin-left: 0px; */
}

.selected-line {
    width: 26px;
    height: 4px;
    border-radius: 10px;
    background: var(--primarypurple-primarypurple_03, #907AFF);
    margin-top: 2px;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
}
.hidden {
    display: none;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
}

.linkText {
    color: var(--black, #333);
    /* text_01 */
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.mobile-nav-layout {
    width: 100%;
    
    /* height: 60px; */
    /* justify-content: center; */
    box-sizing: border-box;
    padding: 12px 32px;
}

.mobile-nav-content {
    display: flex;
    position: relative;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
    height: 100%;
}


.mobile-logo {
    width: 134.03px;
    height: 36px;
}

.mobile-nav-right {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
}
.mobile-toggle-icon {
    width: 24px;
    height: 24px;
    /* flex布局中子元素缩小比例，当父容器放不下时使用 */
    flex-shrink: 0;
}
.mobile-toggle-container {
    cursor: pointer;
    padding: 6px 0;
}
/* .mobile-nav-right:focus + .mobile-list-container,
.mobile-nav-right:hover + .mobile-list-container {
    display: block;
} */

/* .mobile-nav-right:focus > .mobile-toggle-container > .mobile-toggle-icon,
.mobile-nav-right:hover > .mobile-toggle-container > .mobile-toggle-icon{
    content: url('../../images/expandedToggle.svg');
} */
/* .mobile-list-container:focus + .mobile-nav-right > .mobile-toggle-container > .mobile-toggle-icon,
.mobile-list-container:hover + .mobile-nav-right > .mobile-toggle-container > .mobile-toggle-icon{
    content: url('../../images/expandedToggle.svg');
} */
/* .mobile-list-container:focus,
.mobile-list-container:hover {
    display: block;
} */
.mobile-list-container {
    position: absolute;
    top: 36px;
    left: -32px;
    width: calc(100% + 64px);
    border-radius: 0px 0px 12px 12px;
    background: var(--white, #FFF);
    box-shadow: 0px 10px 20px 0px rgba(22, 22, 22, 0.06);
    box-sizing: border-box;
    padding: 0 32px;
    padding-top: 8px;
    padding-bottom: 16px;
    z-index: 1;
    display: none;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
}
.mobile-list-item {
    list-style: none;
    list-style-position: inside;
}
.mobile-item {
    width: 100%;
    padding: 16px 0;
}
.mobile-link-text {
    color: var(--gray-gray_tsp_01, #333);
    /* text_01_strong */
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}