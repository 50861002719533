
.link {
    text-decoration: none;
    color:inherit;
}
* {
    margin: 0;
    scroll-behavior: smooth;
    padding: 0;
    --width: 1920;
    --height: 1080;
    --scale: 0.6;
}

#root {
    /* padding-top: 32px; */
    --rootvw: 1vw;
    --rootvh: 1vh;
    height: 100vh;
    /* width: 100vh; */
    /* min-width: 800px; */
    /* min-height: 800px; */

}

@font-face {
    font-family: "Alibaba PuHuiTi 2.0";
    src: url("@/fonts/Alibaba-PuHuiTi-Regular.otf"),
        url("@/fonts/Alibaba-PuHuiTi-Regular.ttf"),
}

